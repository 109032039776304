/* *****************************************************************************
*
*  Block Region
*
* ***************************************************************************** */


.block-region {

  @include media-breakpoint-down(md) {
    background-image: url('../images/regions/blue/carte_region_blue.png');
    background-repeat: no-repeat;
    background-position: center 90%;
    background-size: auto 84%;
  }

  .link {
    color: $black;

    &:hover {
      color: $adq-blue;
    }
  }
}


.block-region__img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../images/regions/blue/carte_region_blue.png');
}

.region {
  &--abitibi-temiscamingue { background-image: url('../images/regions/blue/carte_region_abitibi_temiscamingue_blue.png');}
  &--bas-saint-laurent { background-image: url('../images/regions/blue/carte_region_bas_saint_laurent_blue.png');}
  &--capitale-nationale { background-image: url('../images/regions/blue/carte_region_capitale_nationale_blue.png');}
  &--centre-du-quebec { background-image: url('../images/regions/blue/carte_region_centre_du_quebec_blue.png');}
  &--chaudiere-appalaches { background-image: url('../images/regions/blue/carte_region_chaudiere_appalaches_blue.png');}
  &--cote-nord { background-image: url('../images/regions/blue/carte_region_cote_nord_blue.png');}
  &--estrie { background-image: url('../images/regions/blue/carte_region_estrie_blue.png');}
  &--gaspesie-iles-de-la-madeleine { background-image: url('../images/regions/blue/carte_region_gaspesie_iles_de_la_madeleine_blue.png');}
  &--lanaudiere { background-image: url('../images/regions/blue/carte_region_lanaudiere_blue.png');}
  &--laurentides { background-image: url('../images/regions/blue/carte_region_laurentides_blue.png');}
  &--laval { background-image: url('../images/regions/blue/carte_region_laval_blue.png');}
  &--mauricie { background-image: url('../images/regions/blue/carte_region_mauricie_blue.png');}
  &--monteregie { background-image: url('../images/regions/blue/carte_region_monteregie_blue.png');}
  &--montreal { background-image: url('../images/regions/blue/carte_region_montreal_blue.png');}
  &--nord-du-quebec { background-image: url('../images/regions/blue/carte_region_nord_du_quebec_blue.png');}
  &--outaouais { background-image: url('../images/regions/blue/carte_region_outaouais_blue.png');}
  &--saguenay-lac-saint-jean { background-image: url('../images/regions/blue/carte_region_saguenay_lac_saint_jean_blue.png');}
}
