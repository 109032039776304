/* *****************************************************************************
*
*  Base style
*
* ***************************************************************************** */

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
  font-family: $font-family-fibraOne-ultraBold;
}

h1, .h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h1-mobile-font-size;
    line-height: $h1-mobile-line-height;
  }
}
h2, .h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h2-mobile-font-size;
    line-height: $h2-mobile-line-height;
  }
}
h3, .h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;

  @include media-breakpoint-down(md) {
    font-size: $h3-mobile-font-size;
    line-height: $h3-mobile-line-height;
  }
}
h4, .h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;
}

h5, .h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

p, li {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $black;
}


a {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $adq-blue;
  transition: all ease-in 0.2s;

  &:hover { text-decoration: underline; }
}

.page-not-found{
  position:relative;
  height: 800px;
  @include media-breakpoint-down(lg){
    height: 600px;
  }
}
.not-found-image {
  min-height: 800px;
  position:absolute;
  background-image: url('/build/images/site/page-not-found.png');
  width: 100%;
  background-size: cover;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  @include media-breakpoint-down(lg){
    background-size: contain;
    min-height: 600px;
  }
}
.not-found-text{
  top: 160px;
}
