/* *****************************************************************************
*
*  Banner
*
* ***************************************************************************** */

.banner-full {

  .wrapper {
    // container bootstrap
    @include make-container-max-widths();
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    // end container bootstrap

    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 30px;
    display: flex;
    flex-direction: column-reverse;
    z-index: 9;
  }

  .bg {
    position: relative;
    background-position: center !important;
    background-size: cover !important;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%), linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
    }
  }

  h5 {
    font-family: $font-family-fibraOne-ultraBold;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    color: $white;
    width: 70%;

    @include media-breakpoint-down(md) {
      width: 100%;
      font-size: $h2-mobile-font-size;
      line-height: $h2-mobile-line-height;
    }
  }

  .content p {
    font-family: $font-family-fibraOne-ultraBold;
    font-size: $font-size-sm;
    color: $white;
  }
}


.banner {
  color: $white;

  .block { z-index: 9; }

  .bg {
    position: relative;
    background-position: center !important;
    background-size: cover !important;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%), linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
    }
  }


  p { color: $white; }
}

.banner-half {
  @include make-container-max-widths();
  margin:0 auto;

  >div {
    display:flex;
    @include media-breakpoint-down(md) { flex-direction: column; }

    >div {
      max-width: 50%;

      @include media-breakpoint-down(md) { max-width: 100%; }

      &.image div {
        padding-top: 0 !important;

        img { width: 100%; }
      }

      &.wrapper {
        display: flex;
        padding: 3rem;

        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;

          p, h1, h2, h3, h4, h5, h6 {color:$adq-blue;}
        }
      }
    }
  }
}
