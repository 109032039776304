/* *****************************************************************************
*
*  Block History on Organisation page
*
* ***************************************************************************** */

.block-history, .block-avantage {
  color: $adq-blue;

  .content {
    border-top: 2px solid $adq-blue;
    padding: 2rem 0;

    @include media-breakpoint-up(lg) { min-height: 180px; }

    &--border-bottom {
      @include media-breakpoint-up(lg) { border-bottom: 2px solid $adq-blue; }
    }
  }

  span {
    font-size: $font-size-xl;

    @include media-breakpoint-down(lg) { font-size: $font-size-xl-mobile; }
  }

  p {
    font-size: 1.2rem;

    @include media-breakpoint-down(md) { font-size: 1rem; }
  }
}
