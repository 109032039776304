/* *****************************************************************************
*
*  Pagination for the listing
*
* ***************************************************************************** */

.pagination {

  li {
    padding: 20px;
  }

  a {
    color: $adq-blue;
  }
}
