/* *****************************************************************************
*
*  Utilities
*
* ***************************************************************************** */

// Padding
// --------------------------------------------------------

.pt-section {
  padding-top: 5rem;

  @include media-breakpoint-down(md) { padding-top: 3rem; }
}
.pb-section {
  padding-bottom: 5rem;

  @include media-breakpoint-down(md) { padding-bottom: 3rem; }
}
.py-section {
  @extend .pt-section;
  @extend .pb-section;
}

// Others
// --------------------------------------------------------

.font-size-xl {
  font-size: $font-size-xl;

  @include media-breakpoint-down(md) { font-size: 50px; }
}

.border-radius-xl {
  border-radius: $border-radius-xl;
}

.font-weight-medium {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.border-radius-item {
  border-radius: $border-radius-item;
}

.viewport-freeze {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.list-reset {
  list-style-type: none;
  padding-left: 0;

  li { list-style-type: none; }
}

.pointer-none {
  pointer-events: none !important;
}

.w-100--mobile-only {
  @include media-breakpoint-down(sm){
    width: 100% !important;
  }
}

.text-transform-initial {
  text-transform: initial !important;
}

.text-align-center { text-align: center; }

.text-align-center-mobile {
  @include media-breakpoint-down(sm){ text-align: center; }
}


// POSITION classes
// -----------------------------------------------
.position-absolute {
  &--top {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%);
  }

  &--top-left {
    position: absolute !important;
    left: 0;
    top: 0;
  }

  &--top-right {
    position: absolute !important;
    left: auto;
    top: 0;
    right:0;
  }

  &--center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--center-left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  &--center-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  &--bottom {
    position: absolute;
    left: 50%;
    top:auto;
    bottom:0;
    transform: translate(-50%, 0%);
  }

  &--bottom-left {
    position: absolute;
    left: 0;
    top:auto;
    bottom:0;
    transform: translate(0, -50%);
  }

  &--bottom-right {
    position: absolute;
    left: auto;
    right: 0;
    top:auto;
    bottom:0;
    transform: translate(0, -50%);
  }

  &--bottom-out {
    position: absolute;
    left: 50%;
    top:auto;
    bottom:0;
    transform: translate(-50%, 50%);
  }
}
