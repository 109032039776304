/* *****************************************************************************
*
*  Header
*
* ***************************************************************************** */

// When we don't have javascript we still want to see the site
html.no-js .overlay-loading { display: none; }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  transition: all 0.2s ease-in;
  height: auto;
  background-color: transparent;
  background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);

  .adherent-nav a, .adherent-nav button { color:$white; }

  &.active { background: $adq-yellow; }
}

.fixed-nav, .toggle-nav-modal {
  .logo {
    position: relative;
    width: 90px;
    height: auto;
    z-index: 9;

    @include media-breakpoint-down(md) { width: 70px; }

    &.switch_logo.scroll {
      width: 200px;
      @include media-breakpoint-down(md) { width: 150px; }
    }

    .no-scroll { display: none; }
  }

  .toggle-container { margin-left: 2rem; }

  .btn-container button, button.toggle.mobile {
    background: none;
    border: 0;
    outline: none;
    margin-top: 7px;
  }

  .icon-close {
    color: $white;
    font-size: 20px;
    transition: all ease-in 0.2s;

    &:hover { color: $adq-yellow; }
  }

  .icon-menu, .icon-profile {
    color: $white;
    font-size: 25px;
    transition: all ease-in 0.2s;

    &:hover { color: $adq-blue; }
  }
}

.sub-nav-container {
  // container bootstrap
  @include make-container-max-widths();
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  // end container bootstrap

  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  padding-top: 150px;

  @include media-breakpoint-down(md) { padding-top: 120px; }

  .breadcrumbs {
    a, p {
      color:$white;
      font-family: $font-family-fibraOne-ultraBold;
    }

    .icon {
      font-size: 12px;
      margin-left: 11px;
      font-weight: 900;

      &:before { color: $white; }
    }

    @include media-breakpoint-down(sm) { display: none; }
  }
}

/* *****************************************************************************
*
*  Header transparent with blue text
*
* ***************************************************************************** */

.product-page, .post-page, .adherent-page {
  header {

    .adherent-nav a, .adherent-nav button { color:$adq-blue; }

    &.active {
      .adherent-nav a, .adherent-nav button { color:$white; }
    }

    a, p {
      color: $white;

      .product-page & { color: $adq-blue; }
    }
  }

  .sub-nav-container {
    .breadcrumbs a, .breadcrumbs p, .breadcrumbs span:before { color:$adq-blue; }
  }
}

/* *****************************************************************************
*
*  Dropdown menu when we are connected
*
* ***************************************************************************** */

.adherent-nav {
  display: flex;
  align-items: center;
  position: relative;

  .content {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 0;
    background: $white;
    width: 270px;
    padding: 1rem;

    &.show { display: flex; }

    a {
      color: $adq-blue;
      display: block;
      padding-bottom: 8px;
    }

    .link-bold {
      font-family: $font-family-fibraOne-bold;

      a { font-family: $font-family-fibraOne-bold; }
    }
  }
}

// announce banner start
.announce-adq {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 300;
  background-color: $adq-blue;

  a {
    text-transform: uppercase;
    color: $white;
    margin: 0 auto;
  }

  + .fixed-nav { margin-top: 45px; }

  @include media-breakpoint-down(md) {
    a { font-size: 0.70em; }
  }
}

// announce banner end
