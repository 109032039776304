/* *****************************************************************************
*
*  Utilities
*
* ***************************************************************************** */

// position
// ----------------------------------
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (top: top, bottom: bottom, left:left, right:right) {
      @each $size, $length in $spacers {

        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: ($length * 0.7) !important;
        }
      }
    }
  }
}


// z-index
// ----------------------------------
$i: 1;
@while ($i <= 50) {
  .z-index-#{$i} { z-index: $i !important; }

  $i: $i+1;
}

// Border width
// ----------------------------------
$i: 1;
@while ($i < 11) {
  .border-width-#{$i} { border-width: $border-width * $i !important; }

  $i: $i+1;
}

// SVG fill
// ----------------------------------
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {

    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $color, $value in $theme-colors {
      .svg-fill#{$infix}--#{$color} {
        fill: $value;

        ._svg-fill { fill: $value; }
        ._svg-stroke { stroke: $value; }
      }
    }
  }
}

// SVG stroke
// ----------------------------------
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $color, $value in $theme-colors {
      .svg-stroke#{$infix}--#{$color} {
        stroke: $value !important;
      }
    }
  }
}

// SVG size
// ----------------------------------
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $spacer, $value in $spacers {
      .svg-size#{$infix}--#{$spacer} {
        //@include size(($value*.8)+.75);
        width: ($value*.8)+.75;
      }
    }
  }
}

/* *****************************************************************************
*
*  Backgrounds
*
* ***************************************************************************** */

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // theme-colors
    @each $color, $value in $theme-colors {
      .bg#{$infix}--#{$color} { background: $value; }
    }

    // GRAYS theme-colors
    @each $toneValue, $value in $grays {
      .bg-gray#{$infix}--#{$toneValue} { background: $value; }
    }

    .bg#{$infix}--none { background: none; }
  }
}


/* *****************************************************************************
*
*  Colors
*
* ***************************************************************************** */

// Theme colors
@each $color, $value in $theme-colors {
  .color--#{$color} { color: $value !important; }
}

// Gray tones
@each $toneValue, $value in $grays {
  .color-gray--#{$toneValue} { color: $value !important; }
}



/* *****************************************************************************
*
*  Product max width breakpoint
*
* ***************************************************************************** */

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-product-max-widths($max-widths: $product-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $product-max-widths in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $product-max-widths;
    }
  }
}
