.block-category-product {
  h2 { @include media-breakpoint-down(sm) { text-align: center; } }

  .align-btn {
    text-align: right;
    @include media-breakpoint-down(md) { text-align: center; }
  }

  .icon {
    &:before {
      font-family: 'ADQ icon';
      position: absolute;
      font-size: 70px;
      top: 0;
      left: 0;
      color: $white;
      transition: all ease-in 0.2s;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-color: $adq-blue;
      width: 70px;
      height: 70px;
      z-index: -1;
      border-radius: 50px;
      transition: all ease-in 0.2s;
    }
  }

  .container-icon {
    min-height: 80px;

    a {
      font-family: $font-family-fibraOne-semiBold !important;
      color: $black;
      padding-left: 5.5rem;
    }
  }

  .container-icon a:hover {
    text-decoration: none;
    color: $adq-blue;

    &:before { color: $adq-blue; }
    &:after { background-color: $adq-yellow; }
  }
}
