.block-homepage-certif-info {
  background-image: url('../images/fleur-de-lys-branding.svg');
  background-position: center;
  background-size: cover;

  @include media-breakpoint-down(md) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
