/* *****************************************************************************
*
*  for generic page
*
* ***************************************************************************** */

.generic-page-header {
  text-align: left;
  padding: 22% 0 2%;

  @include media-breakpoint-down(md) {
    padding-top: 25%;
  }

  .nbitems {
    font-size: $font-size-sm;
    text-transform: uppercase;
  }
}
