/* *****************************************************************************
*
*  For region
*
* ***************************************************************************** */

.region__header.directory-list-header {
  text-align: left;
  background-color: $adq-blue;
  padding: 15% 0 2%;

  @include media-breakpoint-down(md) {
    padding-top: 25%;
  }

  .nbitems {
    font-size: $font-size-sm;
    text-transform: uppercase;
  }
}
