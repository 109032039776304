/* *****************************************************************************
*
*  Search Bar
*
* ***************************************************************************** */

.search-bar-from {
  width: 100%;
  max-width: 450px;

  &--full { max-width: none; }

  @include media-breakpoint-down(sm) { max-width: none; }
}

.search-bar {
  position: relative;
  width: 100%;

  @include media-breakpoint-down(sm) {
    width: 0;
    overflow: hidden;
    transition: all ease-in 0.3s;

    &--full {
      width: 100%;
      overflow: inherit;
    }

    &.open { width: 100%; }
  }

  input {
    width: 100%;
    border-radius: 50rem;
    padding: 0.5rem 1rem;
    border: 0;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 0;
    z-index: 9;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.loop-mobile {
  text-align: right;
  margin-top: 7px;
  position: absolute;
  right: 1.3rem;

  svg {
    width: 24px;
    height: 24px;
  }
}
