/* *****************************************************************************
*
*  Detail Article
*  css for blog
*
* ***************************************************************************** */

.post-details-content {
  .main-image span , figure {
    overflow: hidden;
    border-radius: $border-radius-custom;
  }

  h1, h2, h3, h4, h5, h6 { color:$adq-blue; margin-bottom: 1.5rem; }

  .main-image {
    margin: 25% auto 2.5rem;
    display: block;
    max-width: 600px;

    @include media-breakpoint-down(sm) { margin-top: 25%; }

    span {
      display: block;

      img { width: 100%; }
    }
  }
  .post-category, .post-category a {
    text-transform: uppercase;
    margin: 0;
    font-family: $font-family-fibraOne-ultraBold;
  }

  .post-category { margin: 2rem 0; }

  .element-list-wrapper {
    display: block;
    margin-top: 20px;
    .element-list {
      width: 100%;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .generic-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: .75rem;
        height: auto;
        width: 245px;
        padding: 2rem;
        background-color: #fff;
        margin: 10px auto auto auto;
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          max-width: 175px;
          max-height: 175px;
          margin: 0 auto;
          -o-object-fit: contain;
          object-fit: contain;
          @include media-breakpoint-down(xs) {
            max-width: unset;
            max-height: unset;
          }
        }
        .item-title a {
          font-family: $font-family-fibraOne-ultraBold;
        }
        .item-subtitle {
          margin-bottom: 0;
        }
      }
      .post-item {
        flex-direction: column;
        width: 240px;
        .post-title {
          padding-top: 1rem;
        }
        img {
          border-radius: .75rem;
          height: auto;
          width: 240px;
          @include media-breakpoint-down(xs) {
            width: 100%;
          }
          @include media-breakpoint-down(md) {
            width: 95%;
          }
        }
        .post-category {
          margin: 0;
          a {
            color: #fff;
            font-family: FibraOne-Regular;
            font-size: .75rem;
            font-weight: 400;
            line-height: 1.5;
            text-align: left;
          }
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
        @include media-breakpoint-down(md) {
          width: 50%;
        }
      }
    }
  }
}

.editable figure.align-right {
  float: right;
  margin: 0 0 1.5em 1.5em;
}

.editable figure.align-left {
  float: left;
  margin: 0 1.5em 1.5em 0;
}

// centering the images, on figure, in blogs
.post-page {
  figure {
    position: relative;
    img {
      border-radius: .875rem;
      margin: 0 50%;
      transform: translateX(-50%);

    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

