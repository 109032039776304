/* *****************************************************************************
*
*  Footer
*
* ***************************************************************************** */

.footer {
  padding: 7% 0;

  @include media-breakpoint-down(sm){ text-align: center; }
}


.footer-links {
  color: $white;

  .link {
    color: $white;
    text-transform: uppercase;
    font-family: $font-family-fibraOne-ultraBold;

    @include media-breakpoint-down(md) { font-size: 0.875rem; }


    &.small {
      text-transform: none;
      font-size: 14px;
      font-family: $font-family-fibraOne-regular;
    }
  }
}

.copyrights {
  font-size: 0.7rem;
  opacity: 0.5;
  line-height: 1.2rem;
}
