/* *****************************************************************************
*
*  Icons
*
* ***************************************************************************** */

.icon__external-link {
  width: 17px;
  height: auto;
  vertical-align: sub;
}


.icon__instagram, .icon__facebook {
  width: 25px;
  height: auto;
  transition: all ease-in 0.2s;

  &:hover { opacity: 0.5; }

  @include media-breakpoint-down(md) { width: 30px; }
}
