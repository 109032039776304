/* *****************************************************************************
*
*  Links
*
* ***************************************************************************** */


.link {
  transition: all ease-in 0.2s;
  
  &:hover { text-decoration: underline; }
}
