/* *****************************************************************************
*
*  Block certification on homepage
*
* ***************************************************************************** */

.block-home-certifications {

  img {
    height: 150px;

    @include media-breakpoint-down(md) { height: 100px; }
  }

  .link {
    transition: all ease-in 0.2s;

    &:hover { transform: translateY(-10px); }
  }
}
