/* *****************************************************************************
*
*  Block statistic on Pourquoi Adhérer page
*
* ***************************************************************************** */

.block-statistic {
  span {
    font-size: $font-size-xl;

    @include media-breakpoint-down(lg) { font-size: $font-size-xl-mobile; }
  }
}
