/* *****************************************************************************
*
*  Slick Carousel
*
* ***************************************************************************** */

.margin-bottom-negatif {
  margin-bottom: -8% !important;
  position: relative;
  z-index: 3;

  @include media-breakpoint-down(md) { margin-bottom: 0 !important; }
}

.carousel-pagination > span { display :none; }

.carousel-container {
  position: relative;

  .widget-carousel {

    .slick-list { padding:0 !important; }
    .slick-slide {
      @include make-container-max-widths();
      opacity: 0.3;
      transition: opacity 1s ease-in-out;

      &.slick-active { opacity: 1; }
    }

    .carousel-item-details {
      display: flex;
      padding-bottom: $carousel-image-y-translation;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
        width: 100vw;
      }

      .carousel-item-description-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: $carousel-tile-radius;
        width: 66%;
        min-height: 500px;
        padding: 72px 20% 72px 100px;
        background-color: $adq-blue;

        @include media-breakpoint-down(md) {
          width: 95%;
          padding: 20px 30px;
          min-height: auto;
          margin: 0 auto;
          margin-top: 50px;
          padding-top: 100px;
        }

        h3 { margin-bottom: 30px; }

        h3 a {
          font-size: $h2-font-size;
          font-family: $font-family-fibraOne-ultraBold;
          line-height: $h2-line-height;

          &:hover {
            text-decoration: none;
            color: $adq-yellow;
          }
        }

        p, h3 a ,&>div {
          color: $white;
        }

        .carousel-item-btn {
          background-color: $adq-yellow;
          color: $adq-blue;
          font-family: $font-family-fibraOne-ultraBold;
          border-radius: $rounded-pill;
          text-transform: uppercase;
          padding: $button-padding;
          font-size: $font-size-sm;
          display: inline-flex;

          &:hover {
            color: $adq-blue;
            background-color: darken($adq-yellow, 10%);
            text-decoration: none;
          }
        }
      }

      .carousel-item-img-container {
        width: 33%;
        display: inline-block;
        transform: translate(-$carousel-image-x-translation, $carousel-image-y-translation);

        @include media-breakpoint-down(md) {
          width: 80%;
          transform: none;
          margin: 0 auto;
          margin-bottom: -100px;
        }

        img {
          border-radius: $carousel-tile-radius;
          width: 100%;
        }
      }
    }
  }
  .carousel-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;

    .carousel-controls {
      display: flex;
      @include make-container-max-widths();
      margin: 0 auto;

      .carousel-title { display: none; }

      button {
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: $adq-blue;
        border: none;
        border-radius: $rounded-pill;
        height: 45px;
        width: 45px;

        &.slick-arrow {
          font-family: $font-family-fibraOne-bold;
          color: $white;
          font-size: 12px;
          position: relative;

          &:before {
            content: "\e914";
            font-family: 'ADQ icon';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          span { display: none; }
        }

        &.carousel-previous { transform: rotate(180deg); }
      }

      .carousel-pagination {
        display: inline-flex;
        margin-bottom: 0;
      }


    }
  }
}

.slick-dots {
  padding: 0 10px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  li {
    list-style: none;
    display: inline-flex;
    background-color: $adq-blue;
    width: 10px;
    height: 10px;
    margin: 17px;
    border-radius: $rounded-pill;
    cursor: pointer;

    &.slick-active {
      border: 3px solid $adq-blue;
      background-color: $white;
    }

    button { display: none; }
  }
}

.content .element-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 6rem;

  @include media-breakpoint-down(md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .main-wrapper.with-subnav & {
    padding-top: 0;
    padding-bottom: 0;
  }

  .element-list-title {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    color: $adq-blue;
    width: 70%;
    max-width: 798px;
    align-self: center;

    @include media-breakpoint-down(md) {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
      font-size: $h2-mobile-font-size;
      line-height: $h2-mobile-line-height;
      padding: 0 15px;
    }

  }
  .element-list-link {
    width: 30%;
    max-width: 342px;
    text-align: right;

    @include media-breakpoint-down(md) { width: 100%; }

    a {
      background-color: $adq-yellow;
      color: $adq-blue;
      font-family: $font-family-fibraOne-ultraBold;
      border-radius: $rounded-pill;
      text-transform: uppercase;
      padding: $button-padding;
      font-size: $font-size-sm;
      display: inline-flex;
      text-align: center;

      &:hover {
        color: $adq-blue;
        background-color: darken($adq-yellow, 10%);
        text-decoration: none;
      }
    }

  }
  .element-list-carousel {
    overflow: hidden;
    flex-basis: 100%;
    padding-top: 6rem;

    @include media-breakpoint-down(md) { padding-top: 1rem; }

    .generic-item {
      padding: 2rem;

      .margin-wrap {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        justify-content: flex-end;
        border-radius: $carousel-tile-radius;
        height: 350px;
        width: 280px;
        padding: 2rem;
        background-color: $white;
        margin:0 auto;

        img {
          width: 100%;
          height: 100%;
          max-width: 175px;
          max-height: 175px;
          margin: 0 auto;
          object-fit: contain;
        }

        h5.item-title a {
          font-family: inherit;

        }

        .item-subtitle p {
          margin: 0;
        }
      }
    }
  }
}
