/* *****************************************************************************
*
*  Buttons
*
* ***************************************************************************** */

.btn {
  background-color: $adq-yellow;
  border-radius: $rounded-pill;
  color: $adq-blue;
  text-transform: uppercase;
  font-family: $font-family-fibraOne-ultraBold;
  font-size: $font-size-sm;
  padding: $button-padding;
  transition: all ease-in 0.2s;
  cursor: pointer;

  &:hover {
    color: $adq-blue;
    background-color: darken($adq-yellow, 10%);
    text-decoration: none;
  }

  @include media-breakpoint-down(md) { padding: 1rem 1.5rem; }

  &--blue {
    background-color: $adq-blue;
    color: $white;

    &:hover {
      color: $white;
      background-color: lighten($adq-blue, 10%);
    }
  }

  &--small {
    padding: 0.625rem 1.625rem;
  }
}
