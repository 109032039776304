/* *****************************************************************************
*
*  Burger menu
*
* ***************************************************************************** */

body.is-reveal-open { overflow: hidden; }

.reveal-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0,0,0,.5);
  overflow-y: scroll;
}

.reveal {
  display: none;
  backface-visibility: hidden;
  padding: 2em;
  border: .0625rem solid #d1d0cd;
  border-radius: 0;
  background-color: #fff;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;

  z-index: 999;
}

.toggle-nav-modal {
  &.reveal {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    padding: 0;
    border: 0;
    background-color: $adq-blue;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  .toggle-nav-item.active .toggle-nav-item-link { opacity: 1; }

  .toggle-nav-item-link {
    color: $white;
    opacity: 0.3;
    transition: all ease-in 0.2s;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }

}
