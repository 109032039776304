// $icomoon-font-family: "icomoon" !default;

@font-face {font-family: 'FibraOne-Regular';src: url('../fonts/FibraOne-Regular.eot');src: url('../fonts/FibraOne-Regular.eot?#iefix') format('embedded-opentype'),url('../fonts/FibraOne-Regular.woff2') format('woff2'),url('../fonts/FibraOne-Regular.woff') format('woff'),url('../fonts/FibraOne-Regular.ttf') format('truetype');}
@font-face {font-family: 'FibraOne-UltraBold';src: url('../fonts/FibraOne-UltraBold.eot');src: url('../fonts/FibraOne-UltraBold.eot?#iefix') format('embedded-opentype'),url('../fonts/FibraOne-UltraBold.woff2') format('woff2'),url('../fonts/FibraOne-UltraBold.woff') format('woff'),url('../fonts/FibraOne-UltraBold.ttf') format('truetype');}
@font-face {font-family: 'FibraOne-SemiBold';src: url('../fonts/FibraOne-SemiBold.eot');src: url('../fonts/FibraOne-SemiBold.eot?#iefix') format('embedded-opentype'),url('../fonts/FibraOne-SemiBold.woff2') format('woff2'),url('../fonts/FibraOne-SemiBold.woff') format('woff'),url('../fonts/FibraOne-SemiBold.ttf') format('truetype');}
@font-face {font-family: 'FibraOne-Bold';src: url('../fonts/FibraOne-Bold.eot');src: url('../fonts/FibraOne-Bold.eot?#iefix') format('embedded-opentype'),url('../fonts/FibraOne-Bold.woff2') format('woff2'),url('../fonts/FibraOne-Bold.woff') format('woff'),url('../fonts/FibraOne-Bold.ttf') format('truetype');}
@font-face {font-family: 'FibraOne-Heavy';src: url('../fonts/FibraOne-Heavy.eot');src: url('../fonts/FibraOne-Heavy.eot?#iefix') format('embedded-opentype'),url('../fonts/FibraOne-Heavy.woff2') format('woff2'),url('../fonts/FibraOne-Heavy.woff') format('woff'),url('../fonts/FibraOne-Heavy.ttf') format('truetype');}


@font-face {
  font-family: 'ADQ icon';
  src:  url('../fonts/ADQ_icomoon.eot?9emtsm');
  src:  url('../fonts/ADQ_icomoon.eot?9emtsm#iefix') format('embedded-opentype'),
    url('../fonts/ADQ_icomoon.ttf?9emtsm') format('truetype'),
    url('../fonts/ADQ_icomoon.woff?9emtsm') format('woff'),
    url('../fonts/ADQ_icomoon.svg?9emtsm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ADQ icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-cat_boissons:before {
  content: "\e900";
}
.icon-cat_boulangeries:before {
  content: "\e901";
}
.icon-cat_confiseries:before {
  content: "\e902";
}
.icon-cat_fruits-legumes:before {
  content: "\e903";
}
.icon-cat_huile:before {
  content: "\e904";
}
.icon-cat_mets-prepares:before {
  content: "\e905";
}
.icon-cat_noix:before {
  content: "\e906";
}
.icon-cat_nourriture-animaux:before {
  content: "\e907";
}
.icon-cat_pates-legumineuses:before {
  content: "\e908";
}
.icon-cat_poissons:before {
  content: "\e909";
}
.icon-cat_cerealiers:before {
  content: "\e90a";
}
.icon-cat_laitiers:before {
  content: "\e90b";
}
.icon-cat_soupes:before {
  content: "\e90c";
}
.icon-cat_viandes:before {
  content: "\e90d";
}
.icon-check:before {
  content: "\e90e";
}
.icon-close:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-link_out:before {
  content: "\e911";
}
.icon-menu:before {
  content: "\e912";
}
.icon-profile:before {
  content: "\e913";
}
.icon-arrow:before {
  content: "\e914";
}
