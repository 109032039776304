/******************************************************************************
*
*  Page of one product
*
* ***************************************************************************** */

body.product.product-adq .container, body.adherent-page .container.adherent {
  @include make-container-max-widths();
  padding: 0;
}

.element-title {
  font-size: $font-size-sm;
  text-transform: uppercase;
  font-family: $font-family-fibraOne-ultraBold;
  line-height: $pagination-line-height;
}

// .product-adq {

.product-container {
  margin-top: 10rem;
  margin-bottom: 2rem;
  display: flex;
  position: relative;
  // padding-top: 20%;
  padding-top: 10%;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    margin-top: 0;
    padding-top: 25%;
  }
}

.product-image {
  width: 30%;
  z-index: 2;
  position: absolute;

  @include media-breakpoint-down(md) {
    width: 100%;
    position: relative;
  }

  .image {
    border-radius: $border-radius-xl;
    overflow: hidden;
    min-width: 162px;
    min-height: 162px;
    background-color: $white;

    @include media-breakpoint-down(md) { text-align: center; }
  }

  img { max-width: 100%; }
}

.all-certif {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .certif:before {
    display: block;
    position: relative;
    bottom: 0;
    left:0;
    margin:$custom-control-gutter;
  }
}

.certif:before {
  content: "";
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  width:  94px;
  height: 78px;
  background-repeat: no-repeat;
  background-size: contain;
}

.certif {
  &.adq:before{background-image:url("/build-front/icons/AlimentsDuQuebec_Logo.svg");}
  &.adqb:before{background-image:url("/build-front/icons/AlimentsDuQuebec_Logo_Bio.svg");}
  &.apq:before{background-image:url("/build-front/icons/AlimentsPreparesAuQuebec_Logo.svg");}
  &.apqb:before{background-image:url("/build-front/icons/AlimentsPreparesAuQuebec_Logo_Bio.svg");}
}

.product-tile {
  @include make-product-max-widths();
  padding: 0 0 0 18%;
  margin: $product-padding 0 auto auto;
  background-color: $adq-blue;
  color: $white;
  border-radius: $border-radius-xl;

  @include media-breakpoint-down(md) {
    padding: 20px;
    margin: -30px auto 0;
  }

  p { color:$white; }

  &.certif-adqb, &.certif-apqb { background-color: $adq-green-bio; }

  &>div {
    padding: $product-padding $product-padding $product-padding 0;

    @include media-breakpoint-down(md) {
      padding: 30px 0;
    }

    &.product-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .product-name {
    font-family: $font-family-fibraOne-ultraBold;
    font-size: $h3-font-size;
    line-height: $pagination-line-height;
    text-transform: lowercase;
    word-break: break-word;

    &::first-letter { text-transform: uppercase; }
  }

  .distributor { font-family: $font-family-fibraOne-semiBold; }

  .format p {
    font-family: $font-family-fibraOne-ultraBold;
    font-size: $h3-font-size;
  }

  .product-adherent {
    @media (max-width: 991px){ padding-top: 0}

    .image img {
      width: 8em;
      height: 8em;
    }

    .name {
      font-size: $font-size-base;
      font-family: $font-family-fibraOne-ultraBold;
    }

    .address a, .website a, .contact-mail a, .show-all a, .online-store-link a, .partner-link a { color:white; }
  }
}
